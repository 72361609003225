$grey: #121212;
$mobile: 1250;
$smallscreen: $mobile + 1;
$desktop: 1650;

@include breakpoint($desktop) {
.photo-gallery {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    max-width: 1600px;
    padding-top: 16rem;
    padding-bottom: 16rem;
  }
  
  .photo-container {
    position: relative;
    cursor: pointer;
  }
  
  .photo-container:hover {
    img{
      opacity: 50%;
    }
  }
  
  .photo-container img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.906);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal {
    // max-width: 80%;
    max-height: 40%;
  }
  
  .modal img {
    width: 100%;
    height: 50vh;
  }
}







@include breakpoint($smallscreen, $desktop) {
  .photo-gallery {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      max-width: 1200px;
      padding-top: 8rem;
      padding-bottom: 8rem;
    }
    
    .photo-container {
      position: relative;
      cursor: pointer;
    }
    
    .photo-container:hover {
      img{
        opacity: 50%;
      }
    }
    
    .photo-container img {
      width: 100%;
      height: auto;
      display: block;
    }
    
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.906);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000;
    }
    
    .modal {
      // max-width: 80%;
      max-height: 40%;
    }
    
    .modal img {
      width: 100%;
      height: 50vh;
    }
  }





  @include breakpoint(0, $mobile) {
    .photo-gallery {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 8rem;
        padding-bottom: 8rem;
      }
      
      .photo-container {
        position: relative;
        cursor: pointer;
      }
      
      .photo-container:hover {
        img{
          opacity: 50%;
        }
      }
      
      .photo-container img {
        width: 100%;
        height: auto;
        display: block;
      }
      
      .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.906);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
      }
      
      .modal {
        // max-width: 80%;
        max-height: 40%;
      }
      
      .modal img {
        width: 100%;
        height: 50vh;
      }
    }