$grey: #121212;
$mobile: 1250;
$smallscreen: $mobile + 1;
$desktop: 1650;

.fixed-position{
    position: fixed;
}


@include breakpoint($desktop) {
.button-primary{
    width: 275px;
    height: 66px;
    border-radius: 84px;
    border: 4px solid $grey;
    background: rgba(217, 217, 217, 0.00);
    display: flex;
    align-items: center;
    transition: 0.3s;

    a{
        text-align: center;
        width: 275px;
        font-size: 1.2rem;
        text-transform:uppercase;
        text-decoration: none;
        color: $grey;
        font-family: "Inter Thin";
    }
}

.button-primary:hover{
    width: 275px;
    height: 66px;
    border-radius: 84px;
    border: 4px solid $grey;
    background: $grey;
    display: flex;
    align-items: center;

    a{
        text-align: center;
        width: 275px;
        font-size: 1.2rem;
        text-transform:uppercase;
        text-decoration: none;
        color: white;
    }
}
}

@include breakpoint($smallscreen, $desktop) {
    .button-primary{
        width: 275px;
        height: 66px;
        border-radius: 84px;
        border: 4px solid $grey;
        background: rgba(217, 217, 217, 0.00);
        display: flex;
        align-items: center;
        transition: 0.3s;
    
        a{
            text-align: center;
            width: 275px;
            font-size: 1.2rem;
            text-transform:uppercase;
            text-decoration: none;
            color: $grey;
            font-family: "Inter Thin";
        }
    }
    
    .button-primary:hover{
        width: 275px;
        height: 66px;
        border-radius: 84px;
        border: 4px solid $grey;
        background: $grey;
        display: flex;
        align-items: center;
    
        a{
            text-align: center;
            width: 275px;
            font-size: 1.2rem;
            text-transform:uppercase;
            text-decoration: none;
            color: white;
        }
    }
    }

    
  @include breakpoint(0, $mobile) {
        .button-primary{
            width: 255px;
            height: 55px;
            border-radius: 84px;
            border: 2px solid $grey;
            background: rgba(217, 217, 217, 0.00);
            display: flex;
            align-items: center;
            transition: 0.3s;
        
            a{
                text-align: center;
                width: 275px;
                font-size: 1.2rem;
                text-transform:uppercase;
                text-decoration: none;
                color: $grey;
                font-family: "Inter Thin";
            }
        }
        
        .button-primary:hover{
            width: 255px;
            height: 55px;
            border-radius: 84px;
            border: 2px solid $grey;
            background: $grey;
            display: flex;
            align-items: center;
        
            a{
                text-align: center;
                width: 275px;
                font-size: 1.2rem;
                text-transform:uppercase;
                text-decoration: none;
                color: white;
            }
        }
        }