@font-face {
    font-family: 'Inter Thin';
    font-style: normal;
    font-weight: 300;
    src: url('../../public/assets/fonts/inter-v12-latin-300.woff2') format('woff2'); 
  }

  @font-face {
    font-family: 'Inter Regular';
    font-style: normal;
    font-weight: 400;
    src: url('../../public/assets/fonts/inter-v12-latin-regular.woff2') format('woff2'); 
  }
  
  @font-face {
    font-family: 'Inter SemiBold';
    font-style: normal;
    font-weight: 600;
    src: url('../../public/assets/fonts/inter-v12-latin-600.woff2') format('woff2'); 
  }

  @font-face {
    font-family: 'Inter Bold';
    font-style: normal;
    font-weight: 700;
    src: url('../../public/assets/fonts/inter-v12-latin-700.woff2') format('woff2'); 
  }
  
  
  @font-face {
    font-family: 'Inter Black';
    font-style: normal;
    font-weight: 800;
    src: url('../../public/assets/fonts/inter-v12-latin-800.woff2') format('woff2'); 
  }
  