$grey: #121212;
$mobile: 1250;
$smallscreen: $mobile + 1;
$desktop: 1650;

@include breakpoint($desktop) {
.contact-sec {
    position: relative; /* Required for positioning the video */
    width: 100%;
    height: 100vh; /* Adjust the height as needed */
    overflow: hidden;
    color: white; /* Adjust text color to make it readable */
    display: flex;
    align-items: center;
    justify-content: center;
  

  .sec-bold-sec{
    font-family: "Inter Bold";
  }

  h1{
    color: white;
    font-family: "Inter Thin";
    font-size: 10rem;
    mix-blend-mode: difference;
    text-align: center;
  }

  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

}


.contact-sec2{
  display: grid;
  background-color: $grey;
  grid-template-columns: 40% 60%;
  grid-template-rows: 45rem;
  grid-template-areas: 'img text';
  max-width: 1600px;
  margin: 0 auto !important;
  background-color: white;
  padding-top: 8rem;
  padding-bottom: 8rem;

  .img-sec2{
    grid-area: "img";
    display: flex;
    align-items: center;

    img{
      max-width: 600px;
    }
  }

  .text-sec2{
    grid-area: "text";
    display: flex;
    align-items: center;

    p{
      font-size: 1.4rem;
      line-height: 1.5;
      font-family: "Inter Regular";
      color: $grey;
    } 

    h3{
        font-family: "Inter Bold";
        text-transform: uppercase;
        font-size: 1.5rem;
        color: $grey;
        padding-top: 1rem;
      }

    h2{
      font-family: "Inter Thin";
      text-transform: uppercase;
      font-size: 4rem;
      color: $grey;
    }

    #bold-h{
      font-family: "Inter Bold";
    }
  }

}

.contact-sec3-bg{
    background-color: $grey;
}

.contact-sec3{
    display: grid;
    background-color: $grey;
    grid-template-columns: 40% 60%;
    grid-template-rows: 45rem;
    grid-template-areas: 'img text';
    max-width: 1600px;
    margin: 0 auto !important;
    background-color: $grey;
    padding-top: 8rem;
    padding-bottom: 8rem;
  
    .img-sec2{
      grid-area: "img";
      display: flex;
      align-items: center;
  
      img{
        max-width: 600px;
      }
    }
  
    .text-sec2{
      grid-area: "text";
      display: flex;
      align-items: center;
  
      p{
        font-size: 1.4rem;
        line-height: 1.5;
        font-family: "Inter Regular";
        color: white;
      } 
  
      h3{
          font-family: "Inter Bold";
          text-transform: uppercase;
          font-size: 1.5rem;
          color: white;
          padding-top: 1rem;
        }
  
      h2{
        font-family: "Inter Thin";
        text-transform: uppercase;
        font-size: 4rem;
        color: white;
      }
  
      #bold-h{
        font-family: "Inter Bold";
      }
    }
  
  }
  

}

//TABLET
@include breakpoint($smallscreen, $desktop) {
    .contact-sec {
        position: relative; /* Required for positioning the video */
        width: 100%;
        height: 100vh; /* Adjust the height as needed */
        overflow: hidden;
        color: white; /* Adjust text color to make it readable */
        display: flex;
        align-items: center;
        justify-content: center;
      
    
      .sec-bold-sec{
        font-family: "Inter Bold";
      }
    
      h1{
        color: white;
        font-family: "Inter Thin";
        font-size: 10rem;
        mix-blend-mode: difference;
        text-align: center;
      }
    
      .background-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
      }
    
    }
    
    
    .contact-sec2{
      display: grid;
      background-color: $grey;
      grid-template-columns: 40% 60%;
      grid-template-rows: 45rem;
      grid-template-areas: 'img text';
      max-width: 1200px;
      margin: 0 auto !important;
      background-color: white;
      padding-top: 8rem;
      padding-bottom: 8rem;
    
      .img-sec2{
        grid-area: "img";
        display: flex;
        align-items: center;
    
        img{
          max-width: 400px;
        }
      }
    
      .text-sec2{
        grid-area: "text";
        display: flex;
        align-items: center;
    
        p{
          font-size: 1.2rem;
          line-height: 1.5;
          font-family: "Inter Regular";
          color: $grey;
        } 
    
        h3{
            font-family: "Inter Bold";
            text-transform: uppercase;
            font-size: 1.3rem;
            color: $grey;
            padding-top: 1rem;
          }
    
        h2{
          font-family: "Inter Thin";
          text-transform: uppercase;
          font-size: 3rem;
          color: $grey;
        }
    
        #bold-h{
          font-family: "Inter Bold";
        }
      }
    
    }
    
    .contact-sec3-bg{
        background-color: $grey;
    }
    
    .contact-sec3{
        display: grid;
        background-color: $grey;
        grid-template-columns: 40% 60%;
        grid-template-rows: 45rem;
        grid-template-areas: 'img text';
        max-width: 1200px;
        margin: 0 auto !important;
        background-color: $grey;
        padding-top: 8rem;
        padding-bottom: 8rem;
      
        .img-sec2{
          grid-area: "img";
          display: flex;
          align-items: center;
      
          img{
            max-width: 400px;
          }
        }
      
        .text-sec2{
          grid-area: "text";
          display: flex;
          align-items: center;
      
          p{
            font-size: 1.2rem;
            line-height: 1.5;
            font-family: "Inter Regular";
            color: white;
          } 
      
          h3{
              font-family: "Inter Bold";
              text-transform: uppercase;
              font-size: 1.3rem;
              color: white;
              padding-top: 1rem;
            }
      
          h2{
            font-family: "Inter Thin";
            text-transform: uppercase;
            font-size: 3rem;
            color: white;
          }
      
          #bold-h{
            font-family: "Inter Bold";
          }
        }
      
      }
      
  
}


  @include breakpoint(0, $mobile) {
  .contact-sec {
      position: relative; /* Required for positioning the video */
      width: 100%;
      height: 70vh; /* Adjust the height as needed */
      overflow: hidden;
      color: white; /* Adjust text color to make it readable */
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url(../../public/assets/siona-services-background.jpg);
      background-size: cover;
  
    .sec-bold-sec{
      font-family: "Inter Bold";
    }
  
    h1{
      padding-top: 5rem;
      color: white;
      font-family: "Inter Thin";
      font-size: 3rem;
      mix-blend-mode: difference;
      text-align: center;
    }
  
    .background-video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
      display: none;
    }
  
  }


  .contact-sec2{
    display: grid;
    background-color: $grey;
    grid-template-rows: 50% 50%;
    grid-template-areas: 'text' 'img';
    background-color: white;
  
    .img-sec2{
      grid-area: "img";
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 4rem;
  
      img{
        max-height: 20rem;
      }
    }
  
    .text-sec2{
      grid-area: "text";
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 2rem;
      padding-right: 2rem;
      padding-bottom: 4rem;

      p{
        font-size: 1.1rem;
        line-height: 1.9;
        font-family: "Inter Regular";
        color: $grey;
        text-align: center;
        padding-bottom: 1rem;
      } 

      h3{
        text-align: center;
      }
  
      h2{
        font-family: "Inter Thin";
        text-transform: uppercase;
        font-size: 2.7rem;
        text-align: left;
        padding-top: 2rem;
      }
  
      #bold-h{
        font-family: "Inter Bold";
      }
    }
  
  }
  
  .contact-sec3-bg{
    background-color: $grey;
  }
  
  .contact-sec3{
    display: grid;
    background-color: $grey;
    grid-template-rows: 50% 50%;
    grid-template-areas: 'text' 'img';
  
    .img-sec2{
      grid-area: "img";
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 4rem;
  
      img{
        max-height: 20rem;
      }
    }
  
    .text-sec2{
      grid-area: "text";
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 2rem;
      padding-right: 2rem;
      padding-bottom: 4rem;

      p{
        font-size: 1.1rem;
        line-height: 1.9;
        font-family: "Inter Regular";
        color: white;
        text-align: center;
        padding-bottom: 1rem;
      } 

      h3{
        text-align: center;
        color: white;
      }
  
      h2{
        font-family: "Inter Thin";
        text-transform: uppercase;
        font-size: 2.7rem;
        text-align: left;
        padding-top: 2rem;
        color: white;
      }
  
      #bold-h{
        font-family: "Inter Bold";
      }
    }
  
  }
  
}