$grey: #121212;


@include breakpoint($desktop) {
footer{
    height: 20rem;

    .footer-headline{
        display: flex;
        justify-content: center;
        padding-top: 2.5rem;

        h3{
            font-family: "Inter Thin";
            text-transform: uppercase;
            font-size: 1.5rem;
            color: $grey;

            #footer-headline-bold{
                font-family: "Inter Bold";
            }
        }
    }

    .footer-sections{
        display: flex;
        justify-content: space-evenly;
        max-width: 1600px;
        padding-top: 1rem;
        padding-bottom: 2rem;
        margin: 0 auto !important;

        h4{
            font-family: "Inter Bold";
            font-size: 1.2rem;
            color: $grey;
        }

        p{
            font-family: "Inter Regular";
            font-size: 1rem;
            color: $grey;
            line-height: 1.9;
        }

        a{
            font-family: "Inter Regular";
            font-size: 1rem;
            color: $grey;
            line-height: 1.9;
        }
    }
}
}

@include breakpoint(0, $mobile) {
    footer{
        height: 20rem;
    
        .footer-headline{
            display: flex;
            justify-content: center;
            padding-top: 2rem;
            text-align: center;
    
            h3{
                font-family: "Inter Thin";
                text-transform: uppercase;
                font-size: 2rem;
                color: $grey;
    
                #footer-headline-bold{
                    font-family: "Inter Bold";
                }
            }
        }
    
        .footer-sections{
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 0.5rem;
            padding-bottom: 3rem;
    
            h4{
                font-family: "Inter Bold";
                font-size: 1.2rem;
                color: $grey;
                text-align: center;
            }
    
            p{
                font-family: "Inter Regular";
                font-size: 1rem;
                color: $grey;
                line-height: 1.9;
                text-align: center;
            }

            a{
                font-family: "Inter Regular";
                font-size: 1rem;
                color: $grey;
                line-height: 1.9;
                text-align: center;
            }
        }
    }

}

@include breakpoint($smallscreen, $desktop) {
    footer{
        height: 20rem;
    
        .footer-headline{
            display: flex;
            justify-content: center;
            padding-top: 2.5rem;
    
            h3{
                font-family: "Inter Thin";
                text-transform: uppercase;
                font-size: 1.5rem;
                color: $grey;
    
                #footer-headline-bold{
                    font-family: "Inter Bold";
                }
            }
        }
    
        .footer-sections{
            display: flex;
            justify-content: space-evenly;
            max-width: 1600px;
            padding-top: 1rem;
            padding-bottom: 2rem;
            margin: 0 auto !important;
    
            h4{
                font-family: "Inter Bold";
                font-size: 1.2rem;
                color: $grey;
            }
    
            p{
                font-family: "Inter Regular";
                font-size: 1rem;
                color: $grey;
                line-height: 1.9;
            }

            a{
                font-family: "Inter Regular";
                font-size: 1rem;
                color: $grey;
                line-height: 1.9;
            }
        }
    }
    }