$grey: #121212;
$mobile: 1250;
$smallscreen: $mobile + 1;
$desktop: 1650;

@function pem($px, $base: 16) {
    @return calc($px / $base) + em;
  }
  
  @function rem($px, $base: 16) {
    @return calc($px / $base) + rem;
  }
  
  @mixin breakpoint($min, $max: 0) {
    @if $max == 0 {
      @media ( min-width: rem($min) ) {
        @content
      }
    } @else {
      @media ( min-width: rem($min) ) and ( max-width: rem($max) ) {
        @content
      }
    }
  }
  
  @mixin hidden {
    display: none;
    visibility: hidden;
  }
  
  .hidden {
    @include hidden;
  }
  
  
  @mixin show {
    display: block;
    visibility: visible;
  }
  
  .show {
    @include show;
  }
  



@include breakpoint($desktop) {

  .header-bg{
    background-color: $grey;
    position: fixed;
    width: 100%;
    z-index: 100;
}
.header{
    background-color: $grey;
    min-height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // display: none;

    h1{
        color: rgb(255, 255, 255);

        img{
          max-width: 150px;
        }
    }

    max-width: 1600px;
    margin: 0 auto !important;
}

.mobile-menu{
    display: none;
}
.menu{

    a{
        padding-right: 3.2rem;
        font-family: "Inter Regular";
        color: white;
        text-decoration: none;
        font-size: 1.3rem;
        transition: 0.3s;
    }

    a:hover{
        padding-right: 3.2rem;
        font-family: "Inter Regular";
        color: white;
        text-decoration: none;
        font-size: 1.3rem;
        text-decoration: underline;
    }

    button{
        background-color: rgba(0, 0, 0, 0);
        border-style: none;
        color: white;
        font-family: "Inter Regular";
        font-size: 1rem;
        padding-left: 1rem;
        transition: 0.3s;
    }

    button:hover{
        background-color: rgba(0, 0, 0, 0);
        border-style: none;
        text-decoration: underline;
    }
}
}







@include breakpoint($smallscreen, $desktop) {
  
  .header-bg{
    background-color: $grey;
    position: fixed;
    width: 100%;
    z-index: 100;
}
.header{
    background-color: $grey;
    min-height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1{
        color: rgb(255, 255, 255);

        img{
          max-width: 150px;
        }
    }

    max-width: 1200px;
    margin: 0 auto !important;
}

.mobile-menu{
    display: none;
}
.menu{

    a{
        padding-right: 3.2rem;
        font-family: "Inter Regular";
        color: white;
        text-decoration: none;
        font-size: 1.3rem;
        transition: 0.3s;
    }

    a:hover{
        padding-right: 3.2rem;
        font-family: "Inter Regular";
        color: white;
        text-decoration: none;
        font-size: 1.3rem;
        text-decoration: underline;
    }

    button{
        background-color: rgba(0, 0, 0, 0);
        border-style: none;
        color: white;
        font-family: "Inter Regular";
        font-size: 1rem;
        padding-left: 1rem;
        transition: 0.3s;
    }

    button:hover{
        background-color: rgba(0, 0, 0, 0);
        border-style: none;
        text-decoration: underline;
    }
}

}








@include breakpoint(0, $mobile) {

  .header-bg{
    background-color: $grey;
    position: fixed;
    width: 100%;
    z-index: 100;
}
.header{
    background-color: $grey;
    min-height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1{
        color: rgb(255, 255, 255);

        img{
          max-width: 150px;
        }
    }
    padding-left: 2rem;
    padding-right: 2rem;
}

.menu{
    display: none;
}
.mobile-menu {
    position: relative;
  }
  
  .menu-icon {
    cursor: pointer;
    display: block;
    width: 30px;
    height: 24px;
    position: relative;
    z-index: 20;
  }
  
  .menu-icon span {
    display: block;
    height: 3px;
    width: 100%;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: transform 0.2s, background-color 0.2s;
  }
  
  .menu-icon span:nth-child(1) {
    top: 0;
  }
  
  .menu-icon span:nth-child(2) {
    top: 50%;
  }
  
  .menu-icon span:nth-child(3) {
    bottom: 0;
  }
  
  .menu-icon.open span:nth-child(1),
  .menu-icon.open span:nth-child(3) {
    transform: translateY(50%) rotate(-45deg);
  }
  
  .menu-icon.open span:nth-child(2) {
    opacity: 0;
  }
  
  .menu-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $grey;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
  }

  .list-menu{
    height: 50vh;
    a{
        font-family: "Inter Regular";
        color: white;
        text-decoration: none;
        font-size: 2.4rem;
        transition: 0.3s;
        text-transform: uppercase;
        padding-bottom: 3rem;
    }

    a:hover{
        font-family: "Inter Regular";
        color: white;
        text-decoration: none;
        font-size: 1.6rem;
        text-decoration: underline;
    }

    button{
        background-color: rgba(0, 0, 0, 0);
        border-style: none;
        color: white;
        font-family: "Inter Regular";
        font-size: 1.2rem;
        padding-left: 1rem;
        transition: 0.3s;
    }

    button:hover{
        background-color: rgba(0, 0, 0, 0);
        border-style: none;
        text-decoration: underline;
    }
  }

  .lang-buttons-mobile{
    padding-top: 2rem;
    display: flex;
    justify-content: center;
  }
  
  .menu-content ul {
    list-style: none;
    padding: 0;
  }
  
  .menu-content li {
    margin: 20px;
    font-size: 24px;
    color: #fff;
    text-align: center;
  } 
}
  