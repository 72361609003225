$grey: #121212;
$mobile: 1250;
$smallscreen: $mobile + 1;
$desktop: 1650;

@include breakpoint($desktop) {
.about-sec {
    position: relative; /* Required for positioning the video */
    width: 100%;
    height: 100vh; /* Adjust the height as needed */
    overflow: hidden;
    color: white; /* Adjust text color to make it readable */
    display: flex;
    align-items: center;
    justify-content: center;
  

  .sec-bold-sec{
    font-family: "Inter Bold";
  }

  h1{
    color: white;
    font-family: "Inter Thin";
    font-size: 10rem;
    mix-blend-mode: difference;
    text-align: center;
  }

  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

}



.imp-sec3-bg{
  background-color: $grey;
}

.imp-sec3{
  max-width: 1600px;
  margin: 0 auto !important;
  background-color: $grey;
  padding-top: 8rem;
  padding-bottom: 8rem;

  p{
    font-size: 1.4rem;
    line-height: 1.9;
    font-family: "Inter Regular";
    color: white;
    text-align: center;
  } 

  h3{
    font-size: 1.5rem;
    line-height: 1.9;
    font-family: "Inter Bold";
    color: white;
    text-align: center;
  } 

  a{
    font-size: 1.4rem;
    line-height: 1.9;
    font-family: "Inter Bold";
    color: white;
    text-align: center;
  }
  
  .link-imp{
    display: flex;
    justify-content: center;
  }


}

}

//TABLET
@include breakpoint($smallscreen, $desktop) {
  .about-sec {
      position: relative; /* Required for positioning the video */
      width: 100%;
      height: 100vh; /* Adjust the height as needed */
      overflow: hidden;
      color: white; /* Adjust text color to make it readable */
      display: flex;
      align-items: center;
      justify-content: center;
    
  
    .sec-bold-sec{
      font-family: "Inter Bold";
    }
  
    h1{
      color: white;
      font-family: "Inter Thin";
      font-size: 8rem;
      mix-blend-mode: difference;
      text-align: center;
    }
  
    .background-video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }
  
  }
  
  
  .imp-sec3-bg{
    background-color: $grey;
  }
  
  .imp-sec3{
    max-width: 1000px;
    margin: 0 auto !important;
    background-color: $grey;
    padding-top: 4rem;
    padding-bottom: 4rem;
  
    p{
        font-size: 1.4rem;
        line-height: 1.9;
        font-family: "Inter Regular";
        color: white;
        text-align: center;
      } 
    
      h3{
        font-size: 1.5rem;
        line-height: 1.9;
        font-family: "Inter Bold";
        color: white;
        text-align: center;
      } 
    
      a{
        font-size: 1.4rem;
        line-height: 1.9;
        font-family: "Inter Bold";
        color: white;
        text-align: center;
      }
      
      .link-imp{
        display: flex;
        justify-content: center;
      }
  
  }
  
}


  @include breakpoint(0, $mobile) {
  .about-sec {
      position: relative; /* Required for positioning the video */
      width: 100%;
      height: 70vh; /* Adjust the height as needed */
      overflow: hidden;
      color: white; /* Adjust text color to make it readable */
      display: flex;
      align-items: center;
      justify-content: center;
  
    .sec-bold-sec{
      font-family: "Inter Bold";
    }
  
    h1{
      padding-top: 5rem;
      color: white;
      font-family: "Inter Thin";
      font-size: 3rem;
      mix-blend-mode: difference;
      text-align: center;
    }
  
    .background-video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }
  
  }

  .imp-sec3-bg{
    background-color: $grey;
  }
  
  .imp-sec3{
    max-width: 1000px;
    margin: 0 auto !important;
    background-color: $grey;
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
  
  
    p{
        font-size: 1.1rem;
        line-height: 1.9;
        font-family: "Inter Regular";
        color: white;
        text-align: center;
      } 
    
      h3{
        font-size: 1.2rem;
        line-height: 1.9;
        font-family: "Inter Bold";
        color: white;
        text-align: center;
      } 
    
      a{
        font-size: 1.1rem;
        line-height: 1.9;
        font-family: "Inter Bold";
        color: white;
        text-align: center;
      }
      
      .link-imp{
        display: flex;
        justify-content: center;
      }
  
  }
  
}