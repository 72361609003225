$grey: #121212;
$mobile: 1250;
$smallscreen: $mobile + 1;
$desktop: 1650;

@function pem($px, $base: 16) {
    @return calc($px / $base) + em;
  }
  
  @function rem($px, $base: 16) {
    @return calc($px / $base) + rem;
  }
  
  @mixin breakpoint($min, $max: 0) {
    @if $max == 0 {
      @media ( min-width: rem($min) ) {
        @content
      }
    } @else {
      @media ( min-width: rem($min) ) and ( max-width: rem($max) ) {
        @content
      }
    }
  }
  
  @mixin hidden {
    display: none;
    visibility: hidden;
  }
  
  .hidden {
    @include hidden;
  }
  
  
  @mixin show {
    display: block;
    visibility: visible;
  }
  
  .show {
    @include show;
  }



//DESKTOP
@include breakpoint($desktop) {
.home-hero-text{
  position: absolute;
  top: 73vh;
  display: flex;
  justify-content: center;
  width: 100%;

  h1{
    font-size: 3rem;
    text-align: center;
    width: 100%;
    font-family: "Inter Bold";
    text-transform: uppercase;
    color: $grey;
    margin: 0;
    padding-bottom: -30px;
  }

  p{
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
    font-family: "Inter Thin";
    text-transform: uppercase;
    color: $grey;
  }

  .button-hero{
    display: flex;
    justify-content: center;
}
}

.hero{
    height: 110vh;
    background-image: url(../../public/assets/hero.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.sec0{
    background-color: $grey;
}

.sec1-mobile{
  display: none;
}

.sec1{
    display: grid;
    height: 80rem;
    background-color: $grey;
    grid-template-columns: 50% 50%;
    grid-template-rows: 80rem;
    grid-template-areas: 'text gallerie';
    max-width: 1600px;
    margin: 0 auto !important;

    .text-home{
        grid-area: 'text';
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Inter Regular';

        p{
            font-size: 1.4rem;
            line-height: 1.9;
            font-family: "Inter Regular";
            color: white;
        }
    }

    .gallerie-home{
        grid-area: 'gallerie';
        display: flex;
        align-items: center;
        justify-content: flex-end;
  
        .gallery {
          max-width: 500px;
        }
        
        img {
          max-width: 100%;
        }
        
        .nav-buttons {
          margin-top: 10px;
          margin-left: 150px;
          margin-right: 150px;
          display: flex;
          justify-content: space-around;

          img{
            width: 50px;
            transition: 0.3s;
            opacity: 30%;
          }

          #prev:hover{
            opacity: 100%;  
          }

          #next:hover{
            opacity: 100%;
          }
        }
        
    }
}

.sec2{
  display: grid;
  height: 45rem;
  background-color: $grey;
  grid-template-columns: 40% 60%;
  grid-template-rows: 45rem;
  grid-template-areas: 'img text';
  max-width: 1600px;
  margin: 0 auto !important;
  background-color: white;

  .img-sec2{
    grid-area: "img";
    display: flex;
    align-items: center;

    img{
      max-width: 600px;
    }
  }

  .text-sec2{
    grid-area: "text";
    display: flex;
    align-items: center;

    h2{
      font-family: "Inter Thin";
      text-transform: uppercase;
      font-size: 4rem;
      color: $grey;
    }

    #bold-h{
      font-family: "Inter Bold";
    }
  }

}

.sec3{
  background-color: $grey;
}

.img-sec3{
  height: 65rem;
  max-width: 1600px;
  margin: 0 auto !important;
  display: flex;
  justify-content: center;
  align-items: center;

  img{
    max-width: 900px;
    transition: 0.3s;
  }

  img:hover{
    max-width: 1000px;
  }
}
}


//TABLET
@include breakpoint($smallscreen, $desktop) {
  .home-hero-text{
    position: absolute;
    top: 72vh;
    display: flex;
    justify-content: center;
    width: 100%;
  
    h1{
      font-size: 3rem;
      text-align: center;
      width: 100%;
      font-family: "Inter Bold";
      text-transform: uppercase;
      color: $grey;
      margin: 0;
      padding-bottom: -30px;
    }
  
    p{
      font-size: 1.5rem;
      text-align: center;
      width: 100%;
      font-family: "Inter Thin";
      text-transform: uppercase;
      color: $grey;
    }
  
    .button-hero{
      display: flex;
      justify-content: center;
  }
  }
  
  .hero{
      height: 110vh;
      background-image: url(../../public/assets/hero.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom;
      margin: 0;
  }
  
  .sec0{
      background-color: $grey;
  }

  .sec1-mobile{
    display: none;
  }
  
  .sec1{
      display: grid;
      height: 80rem;
      background-color: $grey;
      grid-template-columns: 50% 50%;
      grid-template-rows: 80rem;
      grid-template-areas: 'text gallerie';
      max-width: 1200px;
      margin: 0 auto !important;
  
      .text-home{
          grid-area: 'text';
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: 'Inter Regular';
  
          p{
              font-size: 1.2rem;
              line-height: 1.9;
              font-family: "Inter Regular";
              color: white;
          }
      }
  
      .gallerie-home{
          grid-area: 'gallerie';
          display: flex;
          align-items: center;
          justify-content: flex-end;
    
          .gallery {
            max-width: 400px;
          }
          
          img {
            max-width: 100%;
          }
          
          .nav-buttons {
            margin-top: 10px;
            margin-left: 100px;
            margin-right: 100px;
            display: flex;
            justify-content: space-around;
  
            img{
              width: 50px;
              transition: 0.3s;
              opacity: 30%;
            }
  
            #prev:hover{
              opacity: 100%;  
            }
  
            #next:hover{
              opacity: 100%;
            }
          }
          
      }
  }
  
  .sec2{
    display: grid;
    height: 45rem;
    background-color: $grey;
    grid-template-columns: 40% 60%;
    grid-template-rows: 45rem;
    grid-template-areas: 'img text';
    max-width: 1200px;
    margin: 0 auto !important;
    background-color: white;
  
    .img-sec2{
      grid-area: "img";
      display: flex;
      align-items: center;
  
      img{
        max-width: 400px;
      }
    }
  
    .text-sec2{
      grid-area: "text";
      display: flex;
      align-items: center;
  
      h2{
        font-family: "Inter Thin";
        text-transform: uppercase;
        font-size: 3rem;
      }
  
      #bold-h{
        font-family: "Inter Bold";
      }
    }
  
  }
  
  .sec3{
    background-color: $grey;
  }
  
  .img-sec3{
    height: 65rem;
    max-width: 1600px;
    margin: 0 auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
  
    img{
      max-width: 700px;
      transition: 0.3s;
    }
  
    img:hover{
      max-width: 800px;
    }
  }
  }










  @include breakpoint(0, $mobile) {
    .home-hero-text{
      position: absolute;
      top: 55vh;
      display: flex;
      justify-content: center;
      width: 100%;
    
      h1{
        font-size: 2.3rem;
        text-align: center;
        width: 100%;
        font-family: "Inter Bold";
        text-transform: uppercase;
        color: $grey;
        margin: 0;
        padding-bottom: -30px;
      }
    
      p{
        font-size: 1rem;
        text-align: center;
        width: 100%;
        font-family: "Inter Thin";
        text-transform: uppercase;
        color: $grey;
      }
    
      .button-hero{
        padding-top: 1rem;
        display: flex;
        justify-content: center;
    }
    }
    
    .hero{
        height: 90vh;
        background-image: url(../../public/assets/hero-mobile.jpg);
        
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0;
    }
    
    .sec0{
        background-color: $grey;
    }

    .sec1{
      display: none;
    }
    
    .sec1-mobile{
        background-color: $grey;
        padding-left: 2rem;
        padding-right: 2rem;
        display: flex;
        align-items: center;

        .text-home{
            grid-area: 'text';
            display: flex;
            justify-content: center;
            align-items: flex-start;
            font-family: 'Inter Regular';
            padding-top: 15vh;
    
            p{
                font-size: 1.1rem;
                line-height: 1.9;
                font-family: "Inter Regular";
                color: white;
            }
        }
    
        .gallerie-home{
          grid-area: 'gallerie';
          display: flex;
          justify-content: center;
          padding-top: 15vh;
          padding-bottom: 15vh;
          
          img {
            max-height: 50vh;
          }
          
          .nav-buttons {
            margin-top: 50px;
            margin-left: 30px;
            margin-right: 30px;
            display: flex;
            justify-content: space-around;
  
            img{
              width: 60px;
              transition: 0.3s;
              opacity: 30%;
            }
  
            #prev:hover{
              opacity: 100%;  
            }
  
            #next:hover{
              opacity: 100%;
            }
          }
          
      }
    }
    
    .sec2{
      display: grid;
      height: 50rem;
      background-color: $grey;
      grid-template-rows: 50% 50%;
      grid-template-areas: 'text' 'img';
      background-color: white;
    
      .img-sec2{
        grid-area: "img";
        display: flex;
        align-items: center;
        justify-content: center;
    
        img{
          max-height: 20rem;
        }
      }
    
      .text-sec2{
        grid-area: "text";
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 4rem;

        .button-sec2{
          display: flex;
          justify-content: center;

        }
    
        h2{
          font-family: "Inter Thin";
          text-transform: uppercase;
          font-size: 2.7rem;
          text-align: center;
        }
    
        #bold-h{
          font-family: "Inter Bold";
        }
      }
    
    }
    
    .sec3{
      background-color: $grey;
    }
    
    .img-sec3{
      height: 30rem;
      display: flex;
      justify-content: center;
      align-items: center;
    
      img{
        max-height: 14rem;
      }
    
    }

  }